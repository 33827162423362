import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import { Button } from 'semantic-ui-react';
import gql from 'graphql-tag';

import { __ } from '../../i18n';
import Controller from '../../components/Controller';
import Modal from '../../components/Modal';
import PaymentForm from './Form';
import BoletoPaymentModal from './BoletoPaymentModal';
import PixPaymentModal from './PixPaymentModal';

@inject('store')
@graphql(gql`mutation createChargePayment($createChargePayment: CreateChargePaymentInput!) {
  createChargePayment(input: $createChargePayment) {
    chargePayment {
      id: dbId
      entityId
      modified
      status
      receivedAmount
      link
      reference
      expirationDate
      statusDescription
      method
      user {
        fullname
      }
    }
  }
}`, {
  options: {
    refetchQueries: ['MessageNodeEntityQuery', 'MessageNodeOrganizationQuery']
  }
})
@observer
export default class PaymentAdd extends Controller {
  request = async (values) => {
    const { charge, store, creditCard, boleto, pix } = this.props;

    let method;
    if (creditCard) method = 'CARD';
    if (boleto) method = 'BOLETO';
    if (pix) method = 'PIX';

    if (this.lockPayment) return;
    this.lockPayment = true;

    await this.props.mutate({
      variables: {
        createChargePayment: {
          entityId: store.entity.id,
          installments: values.installments,
          chargeId: charge.id,
          documentNumber: (values && values.cpf) || store.currentUser.documentNumber,
          email: (values && values.email) || store.currentUser.email,
          phone: (values && values.phone) || store.currentUser.phone,
          method,
          cardToken: creditCard ? values.card.cardToken : null
        }
      }
    }).then((res) => {
      if (creditCard) {
        if (res.data.createChargePayment.chargePayment.status === 'FAILED') {
          store.snackbar = { active: true, message: __('Payment of %s has failed', charge.name), success: false };
        } else {
          store.snackbar = { active: true, message: __('%s was paid', charge.name), success: true };
        }
        store.appends.pop();
      } else if (boleto) {
        const { link, reference } = res.data.createChargePayment.chargePayment;

        store.appends.pop();
        store.appends.push(<BoletoPaymentModal boletoLink={link} boletoReference={reference} />);

        store.snackbar = { active: true, message: __('Successfully generated boleto'), success: true };
      } else {
        store.appends.pop();

        const { data: { createChargePayment: { chargePayment: { expirationDate, reference } } } } = res;
        store.appends.push(
          <PixPaymentModal
            expiration={new Date(expirationDate)}
            code={reference}
            paymentAmount={charge.paymentPixAmount}
          />
        );
      }
    }).catch(() => {
      if (creditCard && window.navigator.onLine) {
        this.props.store.appends.push(
          <Modal
            key={'modal-leave'}
            closeIcon="times close"
            onClose={() => this.props.store.appends.pop()}
            closeOnRootNodeClick={false}
            header={__('An error occurred while processing the payment')}
            content={(
              <div style={{ display: 'grid' }}>
                <p style={{ fontSize: 16, fontWeight: 400, textAlign: 'center', margin: 'auto', padding: 25 }}>
                  {__('Check that your card details are correct or try again with another card.')}
                </p>
              </div>
            )}
            actions={[
              <a
                className="wordWrapped"
                target="_blank"
                rel="noreferrer noopener"
              >
                <Button
                  data-action="cancel"
                  key={0}
                  primary
                  floated="right"
                  content={__('Try again')}
                  style={{ marginBottom: '10px' }}
                  onClick={() => this.props.store.appends.pop()}
                />
              </a>,
              <Button
                data-action="cancel"
                key={0}
                basic
                floated="left"
                content={__('Cancel')}
                style={{ marginBottom: '10px' }}
                onClick={() => {
                  this.props.store.appends.pop();
                  this.props.store.appends.pop();
                }}
              />
            ]}
          />
        );
      } else {
        this.props.store.appends.pop();
        store.snackbar = { active: true, message: __('Occured an error during payment. Try to reload.'), success: false, dismissAfter: 5000 };
      }
    }).finally(() => {
      this.setState({ loading: false });
      this.lockPayment = false;
    });
  }

  render() {
    const { charge, boleto, creditCard, pix } = this.props;

    return (
      <Controller
        {...this.props}
        id="AddPayment"
        modal={{ closeIcon: 'times close' }}
        title={
          creditCard ? __('Credit Card') : boleto ? __('Boleto') : __('Pix')
        }
        form={PaymentForm}
        formProps={{ charge, onCancel: null, boleto, creditCard, pix }}
        loading={this.state.loading}
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        onCancel={() => { }}
        onClose={() => this.props.store.appends.pop()}
      />
    );
  }
}
