//--------------------------------
// This is rendered ONLY on SERVER
//--------------------------------
/*eslint prefer-const: off*/
/*eslint-env es6*/
/*eslint import/no-unresolved: 0 */
/*eslint global-require: 0 */
import React from 'react';
import { inject } from 'mobx-react';
import config from '../../../config/server.config';
import splitChunks from '../../../config/splitChunks';
import deployCredentials from '../../../config/deploy-credentials.json';

if (process.env.IS_CLIENT === true) {
  throw new Error(
    'React Component <Html/> should not be included in the client',
  );
}

const isCYBuild = process.env.CY_BUILD === 'true';

const isProductionEnv = (url) => {
  return url.indexOf('.com.br') >= 0 || url.indexOf('.co') >= 0;
};

@inject('store')
export default class Html extends React.Component {
  render() {
    const { store } = this.props;

    const env = process.env.NODE_ENV;
    // Setup devServerURL accordingly ( webpack dev server has a different port )
    const isProduction = ['production', 'stage', 'internal', 'beta'].includes(
      env,
    );
    const devServerURL = isProduction
      ? config.assets.cdn[env]
      : isCYBuild
        ? `http://localhost:${config.http.port}/public/dist`
        : `http://${store.app.host.replace(config.http.port, config.webpack.dev_port)}`;
    const assetsURL = config.assets.bucket[env];

    let hash;

    if (isProduction) {
      hash =
        deployCredentials && deployCredentials.hash
          ? deployCredentials.hash
          : '';
    }

    const cdnBundleURL = hash ? `${devServerURL}/${hash}` : `${devServerURL}`;

    // Setup metadata
    const metadata = {
      locale: store.app.locale,
      title: store.app.title,
      description: store.app.description,
      keywords: '',
      color: store.app.color,
    };

    // Inject store ( used for mobx-connect )
    const injected_store = `window.__STORE = ${JSON.stringify(store, null, isProduction ? 0 : 4)};`;

    const chunkNames = Object.keys(splitChunks.cacheGroups).concat([
      'runtime',
      'main',
    ]);

    return (
      <html lang={metadata.locale}>
        <head>
          <title>
            {metadata.title
              ? metadata.title + ' · ' + store.app.name
              : store.app.name}
          </title>
          <meta charSet="utf-8" />
          <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
          <meta
            name="viewport"
            content="width=device-width, height=device-height, initial-scale=1.0, maximum-scale=1.0"
          />
          <meta name="description" content={metadata.description} />
          <meta name="keywords" content={metadata.keywords} />
          <link
            rel="stylesheet"
            href="https://assets.classapp.co/semantic-16px/semantic.min.css?version=0.0.3"
          />
          <link rel="stylesheet" href={`${cdnBundleURL}/bundle.main.css`} />
          <link
            rel="stylesheet"
            href="//cdn.quilljs.com/1.2.6/quill.snow.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            charSet="UTF-8"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
          />
          <link rel="icon" type="image/x-icon" href="/favicon.ico" />
          <style id="theme">{`.themed { background: #${metadata.color} !important; color: white !important; }`}</style>
          <script dangerouslySetInnerHTML={{ __html: injected_store }} />
          {['production', 'beta'].includes(env)
            ? [
              <script
                key={'zendesk-script'}
                type="text/javascript"
                dangerouslySetInnerHTML={{
                  __html: `/*<![CDATA[*/window.zEmbed||function(e,t){var n,o,d,i,s,a=[],r=document.createElement("iframe");window.zEmbed=function(){a.push(arguments)},window.zE=window.zE||window.zEmbed,r.src="javascript:false",r.title="",r.role="presentation",(r.frameElement||r).style.cssText="display: none",d=document.getElementsByTagName("script"),d=d[d.length-1],d.parentNode.insertBefore(r,d),i=r.contentWindow,s=i.document;try{o=s}catch(e){n=document.domain,r.src='javascript:var d=document.open();d.domain="'+n+'";void(0);',o=s}o.open()._l=function(){var e=this.createElement("script");n&&(this.domain=n),e.id="js-iframe-async",e.src='https://assets.zendesk.com/embeddable_framework/main.js',this.t=+new Date,this.zendeskHost="classapp.zendesk.com",this.zEQueue=a,this.body.appendChild(e)},o.write('<body onload="document._l();">'),o.close()}();
                /*]]>*/`,
                }}
              />,
              <script
                key={'zendesk-script-hide'}
                type="text/javascript"
                dangerouslySetInnerHTML={{
                  __html: `zE(function() {
                  zE.hide();
                });`,
                }}
              />,
              <script
                key={'google-tag-manager'}
                type="text/javascript"
                dangerouslySetInnerHTML={{
                  __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-KTF7JVD');`,
                }}
              />,
            ]
            : null}
          {['production', 'beta'].includes(env) ? (
            <script
              key={'hotjar-script'}
              type="text/javascript"
              dangerouslySetInnerHTML={{
                __html: `(function(h,o,t,j,a,r){
                    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                    h._hjSettings={hjid:564242,hjsv:5};
                    a=o.getElementsByTagName('head')[0];
                    r=o.createElement('script');r.async=1;
                    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                    a.appendChild(r);
                })(window,document,'//static.hotjar.com/c/hotjar-','.js?sv=');`,
              }}
            />
          ) : null}
          <script
            type="text/javascript"
            id="hs-script-loader"
            async
            defer
            src="//js.hs-scripts.com/5967222.js"
          />
        </head>
        <body>
          <div id="root">{this.props.children}</div>
          {chunkNames.map((chunkName) => (
            <script
              key={chunkName}
              src={`${cdnBundleURL}/bundle.${chunkName}.js`}
            />
          ))}
          {isProductionEnv(store.app.host) && [
            <noscript key="ga">
              <iframe
                src="https://www.googletagmanager.com/ns.html?id=GTM-KTF7JVD"
                height="0"
                width="0"
                style={{ display: 'none', visibility: 'hidden' }}
              />
            </noscript>,
          ]}
        </body>
      </html>
    );
  }
}
