import React, { Component } from 'react';
import { Card, Container, Icon } from 'semantic-ui-react';
import { inject, observer } from 'mobx-react';
import InputMask from 'react-input-mask';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';

import { __ } from '../../i18n';
import { validateCPF, simpleDate, clearSnackbar, handleError } from '../../utils';
import Button from '../../components/ui/Button';
import ConfirmationModal from './ConfirmationModal';
import Responsive from '../../components/Responsive';
const CPF_CLASSNAMES = {
  VALID_VALUE: {
    global: 'valid',
    label: 'valid',
    icon: 'valid'
  },
  INVALID_VALUE: {
    global: 'invalid',
    label: 'invalid',
    icon: 'invalid'
  },
  INCOMPLETE: {
    global: '',
    label: 'hidden',
    icon: 'hidden'
  },
  EMPTY: {
    global: '',
    label: 'unchecked',
    icon: 'hidden',
  },
  SIGNED: {
    global: 'valid disabled',
    label: 'valid',
    icon: 'valid',
  },
  DISABLED: {
    global: 'disabled',
    label: 'unchecked',
    icon: 'hidden',
  }
};

const getCpfStatus = ({ validTaxId, currentTaxIdLength, canSign, signer, isInputFocused, edit }) => {
  if (edit) return 'DISABLED';
  if (canSign) {
    if ((currentTaxIdLength > 0 && currentTaxIdLength < 11)) return 'INCOMPLETE';
    if (currentTaxIdLength === 0) return 'EMPTY';
    if (!validTaxId) return 'INVALID_VALUE';
    return 'VALID_VALUE';
  }
  if (signer) return 'SIGNED';
  return 'DISABLED';
};

@inject('store')
@graphql(gql`mutation createSignatureResult($signatureId:ID!, $entityId:ID!, $documentNumber:String!) {
  createSignatureResult(input: { signatureId: $signatureId, entityId: $entityId, documentNumber: $documentNumber }) {
    clientMutationId
  }
}`, {
  options: {
    refetchQueries: ['MessageNodeEntityQuery']
  }
})
@observer
export default class SignatureItem extends Responsive {
  constructor(props) {
    super(props);

    const { store: { currentUser } } = props;
    const userSignature = this.getUserSignatureResult();

    this.state = {
      taxId: userSignature ? userSignature.documentNumber : (currentUser.documentNumber) || '',
      signer: userSignature ? userSignature.signerFullname : '',
      isInputFocused: false
    };
  }

  getUserSignatureResult() {
    const { signature, store: { currentUser: { id } } } = this.props;
    return signature && signature.results && signature.results.nodes && signature.results.nodes.find(res => res.userId == id);
  }

  /**
   * @returns {{
   *  text: string;
   *  type: 'warning' | 'success' | 'expired' | 'canceled';
   * }}
   */
  getStatusText(userSignature) {
    const { signature } = this.props;
    const { dateLimit, status } = signature;
    const lang = this.props.store.app.locale && this.props.store.app.locale.substring(0, 2);

    if (status === 'CANCELED') {
      return { text: __('The contract has been canceled'), type: 'canceled' };
    }

    if (status === 'COMPLETED' || userSignature) {
      if (!userSignature) {
        return { text: __('This contract has already been signed'), type: 'success' };
      }

      return { text: __('Signed in %s', simpleDate(new Date(userSignature.created), true, 'LL, LT', lang)), type: 'success' };
    }

    const date = simpleDate(new Date(dateLimit), true, 'LL, LT', lang);
    if (status === 'PENDING') {
      return { text: __('Expires %s', date), type: 'warning' };
    } else if (status === 'EXPIRED') {
      return { text: __('Expired %s', date), type: 'expired' };
    }
  }

  handleSign() {
    const { taxId } = this.state;
    const isTaxIdValid = validateCPF(taxId);

    if (!isTaxIdValid) return;
    this.props.store.appends.push(
      <ConfirmationModal
        userName={this.props.store.currentUser.fullname}
        documentNumber={taxId}
        onConfirm={() => {
          const { params: { entity_id }, signature: { id } } = this.props;

          clearSnackbar(this.props.store);
          this.props.mutate({
            variables: {
              signatureId: id,
              entityId: entity_id,
              documentNumber: taxId
            }
          }).then(() => {
            this.props.store.snackbar = { active: true, message: __('Contract signed successfully'), success: true, dismissAfter: 3000 };
            this.setState({ signer: this.props.store.currentUser.fullname });
          }).catch((err) => {
            console.error(err);
            if (/signature/i.test(err.message)) {
              this.props.store.snackbar = { active: true, message: handleError('An error occurred while processing the signature'), success: false, dismissAfter: 3000 };
              this.props.updateQuery();
            } else {
              this.props.store.snackbar = { active: true, message: handleError(err.message), success: false, dismissAfter: 3000 };
            }
          });

          this.props.store.appends.pop();
        }}
        onClose={() => this.props.store.appends.pop()}
      />
    );
  }

  render() {
    const { signature: { canSign, status, title }, edit } = this.props;
    const { taxId, signer, isInputFocused } = this.state;

    const userSignature = this.getUserSignatureResult();

    const statusText = this.getStatusText(userSignature);
    const validTaxId = validateCPF(taxId);
    const currentTaxIdLength = taxId.replace(/\.|-|\s/g, '').length;
    const cpfStatus = getCpfStatus({ validTaxId, currentTaxIdLength, canSign, signer, isInputFocused, edit });
    const isCanceled = status === 'CANCELED';
    const cardStyles = { maxWidth: 355, borderRadius: 12, marginRight: 8, marginBottom: '1em' };


    return (
      <Card fluid style={edit ? cardStyles : null}>
        <Card.Content>
          <Container style={{ padding: !this.isMobile() && edit ? '0px 24px 14px' : edit ? '0px 14px 14px' : '' }}>
            <div className="horizontallySpacedItems" style={{ margin: this.isMobile() ? (edit ? '22px 0px 14px' : '') : (edit ? '22px 0px 14px' : '') }}>
              <div className="bold">
                <Icon name="file signature" style={{ color: edit ? 'black' : '#0080ff', padding: 0, margin: '0 .5rem 0 0' }} />
                {title || __('Signature')}
              </div>
            </div>

            <InputMask
              mask="999.999.999-99"
              value={cpfStatus !== 'DISABLED' && taxId}
              onChange={({ target: { value } }) => this.setState({ taxId: value })}
              maskChar={null}
            >
              {
                inputProps =>
                  <div
                    className={`signature-cpf ${CPF_CLASSNAMES[cpfStatus].global} ${isInputFocused ? 'focus' : ''}`}
                    onClick={(event) => {
                      [...event.currentTarget.children].find(item => item.classList.contains('signature-input')).focus();
                    }}
                    tabIndex={0}
                  >
                    {!(isInputFocused && cpfStatus == 'EMPTY') && <span className={CPF_CLASSNAMES[cpfStatus].label}>
                      CPF
                    </span>}
                    <input
                      className="signature-input"
                      placeholder="000.000.000-00"
                      onFocus={() => this.setState({ isInputFocused: true })}
                      onBlur={(event) => {
                        if (!event.relatedTarget) {
                          this.setState({ isInputFocused: false });
                        }
                      }}
                      disabled={!canSign || edit}
                      {...inputProps}
                    />
                    <Icon
                      className={CPF_CLASSNAMES[cpfStatus].icon}
                      name={validTaxId ? 'check' : 'circle exclamation'}
                    />
                  </div>
              }
            </InputMask>

            {!signer ?
              <Button
                full
                round
                text={__('Accept terms and sign contract')}
                style={{ fontSize: '1rem' }}
                onClick={() => { this.handleSign(); }}
                disabled={!validTaxId || !canSign || status === 'COMPLETED' || edit}
              />
              :
              <div className="signature-name" style={{ opacity: isCanceled ? 0.5 : 1 }}>
                <p>
                  {signer}
                </p>
              </div>
            }

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <span className={`signature-status ${statusText.type}`}>
                <Icon name={statusText.type === 'canceled' ? 'times circle' : statusText.type === 'success' ? 'check circle' : statusText.type === 'expired' ? 'exclamation circle' : 'clock outline'} />
                {statusText.text}
              </span>
            </div>
          </Container>
        </Card.Content>
      </Card >
    );
  }
}
