import React from 'react';
import {
  Accordion, Container, Segment, Icon
} from 'semantic-ui-react';
import Form from '../../components/Form';
import { __ } from '../../i18n';
import * as utils from '../../utils';

export default class AccessForm extends Form {
  defaultValues = {
    title: '',
    url: ''
  }

  rules = {
    title: ['required', (values) => this.validateContent('title', values)],
    url: ['required', (values) => this.validateContent('url', values)]
  };

  validateContent = (name, values) => {
    if (name === 'title' && values.trim().length <= 0) throw new Error(__('Title cannot be empty'));
    if (name === 'title' && values.trim().length > 30) throw new Error(__('Maximum length of the title is 30 characters'));
    if (name === 'url' && values.trim().length <= 0) throw new Error(__('URL is not defined'));
    if (name === 'url' && !utils.isUrl(values)) throw new Error(__('URL is invalid'));
  }

  render() {
    const { values, errors } = this.state;

    return (
      <Form id="AccessForm" {...this.props} onSubmit={this.handleSubmit}>
        <Form.Input
          label={__('Title')}
          placeholder={__('Link name')}
          name="title"
          value={values.title}
          onChange={this.onInputChange}
          autoFocus
        />
        {
          errors && errors.title
            ? (
              <div style={{ marginBottom: 14 }}>
                <Icon data-value="error" name="exclamation circle" size="large" color="red" />
                {errors.title}
              </div>
            )
            : null
        }
        <Form.Input
          label={__('URL')}
          placeholder={__('ex: https://classapp.com.br/')}
          name="url"
          value={values.url}
          onChange={this.onInputChange}
        />
        {
          errors && errors.url
            ? (
              <div style={{ marginBottom: 14 }}>
                <Icon data-value="error" name="exclamation circle" size="large" color="red" />
                {errors.url}
              </div>
            )
            : null
        }
      </Form>
    );
  }
}
