import React from 'react';

import Form from '../../components/Form';

import { __ } from '../../i18n';

const typeOptions = [{
  text: __('Web'), value: 'WEB'
}, {
  text: __('iOS'), value: 'iOS'
}, {
  text: __('Android'), value: 'ANDROID'
}, {
  text: __('Windows Phone'), value: 'WINDOWS_PHONE'
// }, {
//   text: __('Cross-platform'), value: 4
// }];
}];

export default class ApplicationForm extends Form {

  defaultValues = {
    type: '',
    name: '',
    description: '',
    appUrl: '',
    published: false,
    author: '',
    urlAuthor: '',
    redirectUri: ''
  }

  rules = {
    name: 'required',
    appUrl: ['required', 'url'],
    urlAuthor: 'url',
    redirectUri: ['required', 'url']
  }

  render() {
    const { values, errors } = this.state;

    return (
      <Form id="ApplicationForm" {...this.props} onSubmit={this.handleSubmit}>
        <Form.Dropdown
          label={__('Type')}
          name="type"
          select
          value={values.type}
          options={typeOptions}
          onChange={this.onSelectionChange}
        />
        <Form.Input
          label={__('Name')}
          name="name"
          value={values.name}
          onChange={this.onInputChange}
          error={errors.name}
        />
        <Form.TextArea
          label={__('Description')}
          name="description"
          value={values.description}
          onChange={this.onInputChange}
        />
        <Form.Input
          label={__('App URL')}
          name="appUrl"
          value={values.appUrl}
          onChange={this.onInputChange}
          error={errors.appUrl}
        />
        <Form.Input
          label={__('Author')}
          name="author"
          value={values.author}
          onChange={this.onInputChange}
          error={errors.author}
        />
        <Form.Input
          label={__('Author URL')}
          name="urlAuthor"
          value={values.urlAuthor}
          onChange={this.onInputChange}
          error={errors.urlAuthor}
        />
        <Form.Input
          label={__('Redirect URI')}
          name="redirectUri"
          value={values.redirectUri}
          onChange={this.onInputChange}
          error={errors.redirectUri}
        />
      </Form>
    );
  }
}
