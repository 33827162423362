/*eslint prefer-const: off*/
/*eslint-env es6*/
import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router';
import cookie from 'react-cookie';
import { Menu, Icon, Sidebar, Container, Loader, Image, Label, Button } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { filter } from 'graphql-anywhere';
import Pubsub from 'pubsub-js';

// import '../assets/css/toast.css';
import '../assets/css/toast.css';

import isEqual from 'lodash/isEqual';
import Snackbar from '../components/Snackbar';

import { Container as NewSnackbar } from '../components/kit/Snackbar/components/Container';
import Avatar from '../components/Avatar';
import Footer from '../components/Footer';
import TopCover from '../components/TopCover';
import PaywallModal from './Trial/PaywallModal';
import KnowMoreModal from '../components/KnowMoreModal';
import ExpiredLimitBanner from '../components/ExpiredLimitBanner';
import DemonstrationBanner from '../components/DemonstrationBanner';
import WelcomeModal from '../components/WelcomeModal';
// import WhatIsNew from '../components/WhatIsNew';
import NoJavascript from '../components/NoJavascript';
// import Announcements from '../components/Announcements';
import Responsive from '../components/Responsive';
import Banner from '../components/Banner';
import { OmieBanner } from '../components/OmieBanner';
import { ZendeskWidget } from '../components/ZendeskWidget';

import EntityItem from './Entity/Item';
import OrganizationItem from './Organization/Item';
import UserInvitations from './User/Invitations';
import UserNotifications from './User/Notifications';

import i18n, { __ } from '../i18n';
import * as utils from '../utils';
import { isNetwork } from  '../app/helpers/isNetwork';
import { sendGoogleAnalyticsEvent } from '../lib/analytics';
import { bannerTheme, getClassPayBannerRedirectLink } from '../lib/bannerSettings';
import PlanLabel from '../components/PlanLabel';
import betaIds from '../lib/betaIds';
import OverdueBanner from '../components/OverdueBanner';
import ElementModifier from '../components/ElementModifier';

const doNotShowBanner = [
  // 106, 164, 296, 297, 436, 534, 776, 809, 828, 897, 958, 1048, 1250, 1256, 1340, 1551, 1606, 1615, 1616, 1617, 1645, 1751, 1767, 1784, 1790, 1795, 1817, 1829, 1831, 1832, 1844, 1873, 1874, 1877, 1878, 1913, 1944, 2008, 2046, 2057, 2058, 2092, 2209, 2283, 2295, 2316, 2320, 2346, 2359, 2360, 2391, 2395, 2396, 2414, 2423, 2435, 2467, 2493, 2494, 2498, 2589, 2590, 2651, 2654, 2693, 2782, 2837, 2852, 2860, 2892, 2896, 2933, 2943, 3012, 3033, 3064, 3784
];
const showBanner = true;

export const checkCanShowBanner = ({ organization, entity, isOverdue, showBanner }) => {
  const productFeatures = organization?.productFeatures?.nodes.filter(pf => pf.identifier === 'attendanceChannel');
  const hasAttendanceChannel = productFeatures?.length > 0;
  const hasMenuConversation = organization?.unleashStatus?.web_menu_conversations && organization?.unleashStatus?.show_banner_menu_conversations;

  const canShowBanner = entity && entity.type === 'ADMIN'
    && hasMenuConversation
    && !hasAttendanceChannel
    && !isOverdue
    && showBanner;

    return canShowBanner;
}

export const omieShowBanner = ({ entity, showBanner }) => {
  // const canOmieShowBanner = entity && entity.type === 'ADMIN'
  //   && showBanner;

  // return canOmieShowBanner;

  return false;
}

const AppUserOrganizationsQuery = gql`query AppUserOrganizationsQuery($limit: Int!) {
  viewer {
    id: dbId
    organizations (limit: $limit, orderBy: { column: CREATED, direction: ASC}) {
      nodes {
        id: dbId
        color
        live
        type
        usage
        fullname
        confMessage
        confMessageStudent
        confMessageStaff
        confMedia
        confCommitment
        confSurvey
        confCharge
        confReport
        confForm
        confReplyStudent
        confMediaStudent
        confTerms
        confPresence
        confAdminList
        entryMethod
        created
        plan
        premiumPeriod
        isDemonstration
        monthlyMessagesCount
        monthlyMessageQuota
        organizationBanners {
          nodes {
            id
            bannerType
          }
        }
        featureToggles {
          web_menu_conversations_notification_bell
        }
        features {
          messages
          messageManager
          readingControl
          files
          surveys
          commitments
          moments
          reports
          videos
          audios
          charges
          forms
          api
          dashboard
          labels
          deleteMessages
          editMessages
          videoConference
        }
        permissions {
          entityScopes
          hasAdmin
        }
        logo {
          id: dbId
          uri
        }
        ...OrganizationItem
        ...TopCover
      }
    }
  }
}
${OrganizationItem.fragments.organization}
${TopCover.fragments.organization}
`;

@inject('store', 'client')
@graphql(gql`query AppQuery {
  viewer {
    id: dbId
    fullname
    phone
    email
    documentNumber
    confirmPhone
    confirmEmail
    hasPassword
    isMaster
    language
    created
    organizations (limit: 1000, orderBy: { column: CREATED, direction: ASC}) {
      nodes {
        id: dbId
        color
        live
        type
        usage
        fullname
        confArrived
        confMessage
        confMessageStudent
        confMessageStaff
        confMedia
        confCommitment
        confSurvey
        confCharge
        confReport
        confForm
        confReplyStudent
        confMediaStudent
        confTerms
        confPresence
        confAdminList
        entryMethod
        created
        plan
        premiumPeriod
        isDemonstration
        monthlyMessagesCount
        monthlyMessageQuota
        organizationBanners {
          nodes {
            id
            bannerType
          }
        }
        featureToggles {
          web_menu_conversations_notification_bell
        }
        features {
          messages
          messageManager
          readingControl
          files
          surveys
          commitments
          moments
          reports
          videos
          audios
          charges
          forms
          api
          dashboard
          labels
          deleteMessages
          editMessages
          videoConference
        }
        network {
          id
        }
        unleashStatus {
          network_communication
        }
        permissions {
          entityScopes
          hasAdmin
        }
        logo {
          id: dbId
          uri
        }
        ...OrganizationItem
        ...TopCover
      }
      totalCount
    }
    entities(limit: 2000) {
      nodes {
        id: dbId
        type
        code
        disabled
        invisible
        hasViewer
        picture {
          uri
          id: dbId
          key
        }
        organization {
          id: dbId
        }
        ...EntityItemEntity
      }
    }
    notifications: notificationsCounter {
      totalCount
    }
    invitations {
      totalCount
    }
  }
}
${OrganizationItem.fragments.organization}
${EntityItem.fragments.entity}
${TopCover.fragments.organization}
`, {
  options: {
    fetchPolicy: 'network-only'
  }
})
@observer
export default class App extends Responsive {
  constructor(props) {
    super(props);

    this.state = {
      drawer: false,
      notifications: false,
    };

    this.subscriptions = {
      ROUTER_GO_BACK: null,
      ROUTER_NAVIGATE: null,
    };
  }

  componentDidMount() {
    const { router } = this.props;
    const { removeLastRouteFromPathname } = this;

    this.openWelcomeModal();
    this.updateWindowWidth();
    window.addEventListener('resize', this.updateWindowWidth);

    this.subscriptions['ROUTER_GO_BACK'] = Pubsub.subscribe(
      'ROUTER_GO_BACK',
      function () {
        router.goBack();
      },
    );

    this.subscriptions['ROUTER_NAVIGATE'] = Pubsub.subscribe(
      'ROUTER_NAVIGATE',
      function (_, route) {
        // ex: 'dashboard'
        const pathNameWithoutLastRoute = removeLastRouteFromPathname(
          router.location.pathname,
        );
        router.push(`${pathNameWithoutLastRoute}/${route}`);
      },
    );
  }

  componentDidUpdate(prevProps) {
    const prevCurrentEntity = prevProps.store?.currentEntity;
    const prevCurrentUser = prevProps.store?.currentUser;
    const isSameUser = prevCurrentUser === this.store?.currentUser;
    const isSameEntity = prevCurrentEntity === this.store?.currentEntity;

    if (!isSameUser || !isSameEntity) {
      Pubsub.publish('STORE_UPDATED', this.props?.store);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);

    Pubsub.unsubscribe(this.subscriptions['ROUTER_GO_BACK']);
    Pubsub.unsubscribe(this.subscriptions['ROUTER_NAVIGATE']);
  }

  updateWindowWidth = () => {
    this.setState({ screenWidth: window.innerWidth });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data, client } = nextProps;

    if ((data.loading && !data.viewer) || !data.viewer) return;

    const { store } = this.props;

    if (store.app.locale !== data.viewer.language) {
      cookie.save('lang', data.viewer.language, { path: '/', maxAge: 60 * 60 * 24 * 7 });
      store.app.locale = data.viewer.language;
      i18n.locale = store.app.locale;
    }

    let entities = [];

    const noOrganizationEntities = data.viewer.entities.nodes.filter(entity => !entity.organization)
      .map(entity => Object.assign({}, entity, { organization: {} }));

    if (noOrganizationEntities && noOrganizationEntities.length) {
      entities = entities.concat([Object.assign({}, {})]).concat(noOrganizationEntities);
    }

    data.viewer.organizations.nodes.forEach((organization) => {
      let organizationEntities = data.viewer.entities.nodes.filter(entity => entity.organization && entity.organization.id === organization.id)
        .map(entity => Object.assign({}, entity, { organization: { ...organization } }));
      const isAdmin = !!organizationEntities.find(entity => entity.type === 'ADMIN');

      entities = entities.concat([Object.assign({}, organization, { isAdmin })]).concat(organizationEntities);
    });

    if (store && store.currentEntity && store.currentEntity.organization && store.currentOrganization && !isEqual(store.currentOrganization, store.currentEntity.organization)) {
      store.currentEntity.organization = store.currentOrganization;
    }

    store.currentUser = Object.assign({}, data.viewer, { entities });

    if (data.viewer.organizations && data.viewer.organizations.nodes
      && data.viewer.organizations.totalCount !== data.viewer.organizations.nodes.length
      && store.currentUser.organizations && store.currentUser.organizations.nodes.length !== data.viewer.organizations.totalCount) {
      const requestArguments = {
        limit: data.viewer.organizations.totalCount
      };
      client.query({ query: AppUserOrganizationsQuery, variables: { ...requestArguments } })
        .then((newData) => {
          data.updateQuery((previousResult) => {
            const updatedResult = { ...previousResult };
            updatedResult.viewer.organizations = newData.data.viewer.organizations;
            return updatedResult;
          });
        }).catch((e) => {
          console.error(e);
        });
    }

    if (nextProps.location.pathname !== this.props.location.pathname) {
      this.props.client.query({
        query: gql`query MessageNodeUserNotificationsQuery {
          viewer {
            id: dbId
            notifications: notificationsCounter {
              totalCount
            }
          }
        }`,
        fetchPolicy: 'network-only'
      });
    }
  }

  sendEvent = () => {
    const { store } = this.props;
    const userId = store.currentUser && store.currentUser.id;

    sendGoogleAnalyticsEvent({
      name: '(Auth) Logout',
      category: 'User Logged Out',
      label: `UserID: ${userId}`,
    }, { store });
  }

  openUserInvitations = () => this.props.store.appends.push(
    <UserInvitations
      onSubmit={() => this.props.store.appends.pop()}
      closeDrawer={() => this.closeDrawer()}
    />
  );

  openKnowMoreModal = () => {
    const { store } = this.props;
    if (utils.isEntityType(store, 'ADMIN')) return this.props.store.appends.push(<PaywallModal />);
    return this.props.store.appends.push(<KnowMoreModal />);
  }

  openWelcomeModal = () => this.props.store.appends.push(<WelcomeModal />);

  toggle = (name) => {
    document.body.classList.toggle('dimmable');
    document.body.classList.toggle('dimmed');
    this.setState({ [name]: !this.state[name] });
  }

  closeDrawer = () => {
    document.body.classList.remove('dimmable');
    document.body.classList.remove('dimmed');
    if (this.state.drawer) this.setState({ drawer: false });
    if (this.state.notifications) this.setState({ notifications: false });
  }

  renderPrepends = () => this.props.store.prepends.map((prepend, i) =>
    <div key={i}>{prepend}</div>
  )

  renderAppends = () => this.props.store.appends.map((append, i) =>
    <div key={i}>{append}</div>
  )

  render() {
    let { store, location } = this.props;
    const __isNetwork = isNetwork(store?.currentOrganization);
    let { currentEntity, currentUser, currentOrganization, app, unleash } = store;
    if (!currentUser || !currentUser.entities) return <Loader active />;

    const isNetworkPathname =
      location?.pathname?.includes('communications') ?? false;

    const firstOrganization = currentUser.entities && currentUser.entities.find(o => !o.code && o.permissions && o.permissions.hasAdmin);
    const menuItems = currentUser.entities.map((entity, key) => {
      let entityPermission;
      if (entity.permissions && entity.permissions.entityScopes && entity.permissions.entityScopes.length) {
        entityPermission = utils.splitPermissionsByFeature(entity.permissions.entityScopes.slice());
      }


      return ((entity.code) ?
        <EntityItem
          key={key}
          entity={entity}
          onClick={this.closeDrawer}
          to={`/entities/${entity.id}/messages`}
          indicator="sidebar"
        /> :
        <OrganizationItem
          key={key}
          isAdmin={(entity.permissions && entity.permissions.hasAdmin) || (entityPermission && entityPermission.entityScopes.length > 0)}
          organization={entity}
          onClick={this.closeDrawer}
          to={((entity.permissions && entity.permissions.hasAdmin) || (entityPermission && entityPermission.entityScopes.length > 0)) && `/organizations/${entity.id}`}
          indicator="sidebar"
          first={firstOrganization && (firstOrganization.id === entity.id)}
        />
      );
    });
    const isCurrentOrganizationAdmin = store.currentOrganization && store.currentOrganization.permissions && store.currentOrganization.permissions.hasAdmin;
    let currentOrganizationEntityPermission;

    if (store.currentOrganization && store.currentOrganization.permissions && store.currentOrganization.permissions.entityScopes && store.currentOrganization.permissions.entityScopes.length) {
      currentOrganizationEntityPermission = utils.splitPermissionsByFeature(store.currentOrganization.permissions.entityScopes.slice());
    }


    let navBarLeft = [];
    let navBarRight = [
      !currentEntity ?
        <Menu.Item id="notificationsDrawer" key={1} onClick={() => this.toggle('notifications')}>
          <Icon name="bell" />
          {currentUser.notifications && currentUser.notifications.totalCount > 0 ?
            currentUser.notifications && currentUser.notifications.totalCount > 99 ?
              <div className="active" style={{ width: '30px', left: '12px' }}>99+</div>
              :
              <div className="active">{currentUser.notifications.totalCount}</div>
            :
            null}
        </Menu.Item> : null,
    ];

    navBarLeft = [
      <Menu.Item id="homeDrawer" key={0} onClick={() => this.toggle('drawer')} icon="bars" />,
      (isCurrentOrganizationAdmin || (currentOrganizationEntityPermission && currentOrganizationEntityPermission.entityScopes.length > 0) || (currentUser && currentUser.isMaster && store.entity)) && store.entity && store.entity.organization ?
        <Menu.Item fitted="horizontally" key={1000} as={Link} to={`/organizations/${store.entity.organization.id}`}>

          <Avatar avatar src={currentOrganization && currentOrganization.logo && currentOrganization.logo.uri} alt={currentOrganization && currentOrganization.fullname} />

          {!this.isMobile() ?
            <span>{currentOrganization && currentOrganization.fullname}</span>
            :
            null}
        </Menu.Item>
        :
        currentOrganization && currentOrganization.fullname ?
          <Menu.Item fitted="horizontally" key={1000}>
            <Avatar avatar src={currentOrganization && currentOrganization.logo && currentOrganization.logo.uri} alt={currentOrganization && currentOrganization.fullname} />
            {!this.isMobile() ?
              <span>{currentOrganization && currentOrganization.fullname}</span>
              :
              null}
          </Menu.Item>
          :
          null,
      ((currentUser.isMaster && currentOrganization && currentOrganization.plan !== 'PREMIUM') ||
        (!currentUser.isMaster && utils.isEntityType(store, 'ADMIN') && currentEntity && currentEntity.organization && currentEntity.organization.plan !== 'PREMIUM')) && !this.isMobile() ? (
        <Menu.Item onClick={() => this.props.store.appends.push(<PaywallModal />)} style={{ border: '1px solid rgba(0, 105, 255, 0.15)' }}>
          <span style={{ color: '#0069FF' }}>{__('Upgrade to new plan')}</span>
        </Menu.Item>
      ) : null,
    ];

    if (currentEntity) {
      const params = {};

      if (location.pathname.replace(/\/$/, '') !== `/entities/${currentEntity.id}/messages`) {
        params.as = Link;
        params.to = `/entities/${currentEntity.id}/messages`;
      }

      if ((currentEntity.organization && currentEntity.organization.confMessage &&
        (currentEntity.organization.confMessageStaff || currentEntity.type !== 'STAFF') &&
        (currentEntity.organization.confMessageStudent || currentEntity.type !== 'STUDENT') &&
        (currentEntity.organization.features.messages) &&
        (!currentEntity.disabled)) || !currentEntity.organization) {
        navBarRight = [
          app.env !== 'production' && app.env !== 'beta' && !this.isMobile() ?
            <Menu.Item fitted="horizontally" key={1002}>
              <Label color={utils.envColor(app.env)} as="a" href={app.url} style={{ margin: 0 }}>{app.env}</Label>
            </Menu.Item>
            :
            null,
          ((currentUser.isMaster && currentOrganization && currentOrganization.plan !== 'PREMIUM') ||
            (!currentUser.isMaster && utils.isEntityType(store, 'ADMIN') && currentEntity && currentEntity.organization && currentEntity.organization.plan !== 'PREMIUM')) && this.isMobile() ? (
            <Menu.Item onClick={() => this.props.store.appends.push(<PaywallModal />)} style={{ border: '' }}>
              <Icon
                name="rocket"
                style={{ color: '#0069FF' }}
              />
            </Menu.Item>
          ) : null,
          // <Menu.Item as={Link} to={`/entities/${currentEntity.id}/questions`} icon="question circle" />, /* Not implemented yet */
          <Menu.Item id="notificationsDrawer" key={1} onClick={() => this.toggle('notifications')}>
            <Icon name="bell" />
            {currentUser.notifications && currentUser.notifications.totalCount > 0 ?
              currentUser.notifications && currentUser.notifications.totalCount > 99 ?
                <div className="active" style={{ width: '30px', left: '12px' }}>99+</div>
                :
                <div className="active">{currentUser.notifications.totalCount}</div>
              :
              null}
          </Menu.Item>,
          currentEntity.organization &&
          (this.isMobile() ?
            <Menu.Item
              style={{ marginRight: '6px' }}
              as={Link}
              to={
                __isNetwork
                ? `/entities/${currentEntity.id}/communications/create`
                : `/entities/${currentEntity.id}/messages/add`
              }
              icon="plus"
            />
            :
            // Provisory write message button
            !isNetworkPathname ?
            <Menu.Item
              style={{ border: '1px solid rgba(34, 36, 38, 0.15)' }}
              as={Link}
              to={
                __isNetwork
                ? `/entities/${currentEntity.id}/communications/create`
                : `/entities/${currentEntity.id}/messages/add`}
              icon="plus"
              id="writeMessage"
            >
              <Icon name="plus" />
              <span style={{ paddingLeft: '0px' }}>{__('Write')}</span>
            </Menu.Item>: null),
          <Menu.Item fitted="horizontally" key={1003} {...params}>
            {this.isMobile() ?
              null
              :
              <span>{(currentEntity.fullname.length > 20) ? (currentEntity.fullname.substring(0, 20) + '...') : currentEntity.fullname}</span>}
            <Avatar avatar src={currentEntity.picture && currentEntity.picture.uri} alt={currentEntity.fullname} />
          </Menu.Item>
        ];
      }
    }

    let entity = currentEntity || (store.entity && store.entity);
    let organization = currentOrganization || (store.entity && store.entity.organization);

    const organizationBanners = (
      organization && organization.organizationBanners && organization.organizationBanners.nodes
    ) || [];
    const hasOrganizationBanners = organizationBanners &&
      organizationBanners.find(__banner => __banner.bannerType === 'FINANCIAL_SUSPENDED');

    const isOverdue = hasOrganizationBanners && (organization.permissions.hasAdmin || currentUser.isMaster);

    const canShowBanner = checkCanShowBanner({ organization, entity, isOverdue, showBanner });

    const checkOmieShowBanner = omieShowBanner({ entity, showBanner });

    // banner may not have an action button
    const canShowActionButton = true;
    let bannerActionButton;

    if (canShowBanner && canShowActionButton) {
      bannerActionButton = bannerTheme.actionButton;
    }

    return (
      <Sidebar.Pushable id="Home">
        <ZendeskWidget
          env={app.env}
          user={{
            name: currentUser?.fullname,
            email: currentUser?.email,
            id: currentUser?.id,
          }}
          plan={currentOrganization?.plan}
        />
        {this.renderPrepends()}
        <div className={'ui loaderbar ' + (store.pageLoading && 'active')} />
        <Menu fixed="top" borderless style={{ zIndex: 2 }} className="menuBarParent">
          <Container className="menuBarContainer">
            <Menu.Menu key={0} position="left">
              {navBarLeft.map(item => item)}
            </Menu.Menu>
            <Menu.Menu key={3} position="right">
              {navBarRight.map(item => item)}
            </Menu.Menu>
          </Container>
        </Menu>
        <OverdueBanner isOverdue={isOverdue} isMobile={this.isMobile()} />
        {canShowBanner && (
          <Banner
            images={bannerTheme.images}
            content={bannerTheme.content}
            actionButton={bannerActionButton}
            closeButton={bannerTheme.closeButton}
            backgroundColor={bannerTheme.backgroundColor}
            name={bannerTheme.name}
            onActionButtonClick={() => {
              this.props.router.push({
                pathname: `/organizations/${store.currentOrganization.id}/channels`,
                state: { channelConfig: true }
              });
            }}
          />
        )}
        {checkOmieShowBanner && (
        <OmieBanner/>
        )}
        <NoJavascript />
        <Sidebar as={Menu} animation="overlay" width="wide" visible={this.state.drawer} vertical inverted>
          {menuItems}
          <Menu.Item data-action="open-user-invitations" onClick={this.openUserInvitations}>
            {
              currentUser.invitations.totalCount > 0 ?
                <Label circular color="brown" size="mini">{currentUser.invitations.totalCount}</Label>
                :
                null
            }
            <span>
              <Icon name="plus" />
              <span>{__('Pending invitations')}</span>
            </span>
          </Menu.Item>
          {
            currentUser.isMaster &&
            <Menu.Item as={Link} to="/admin" onClick={this.closeDrawer} icon="user robot" content={__('Admin')} />
          }
          <Menu.Item as={Link} to="/" onClick={this.closeDrawer} icon="bell" content={__('Notifications')} />
          <Menu.Item as={Link} to="/user/account" onClick={this.closeDrawer}>
            <Icon name="cog" /> {__('User settings')}
            {
              (currentUser.email && !currentUser.confirmEmail) || (currentUser.phone && !currentUser.confirmPhone) ?
                <Icon name="exclamation triangle" className="bold" style={{ float: 'right', color: '#ffb400' }} />
                :
                null
            }
          </Menu.Item>
          <Menu.Item id="logoutLink" as={Link} to="/auth/logout" onClick={() => this.sendEvent()} icon="sign out" content={__('Logout')} />
          <Menu.Item className="wordWrapped" style={{ textAlign: 'center' }}>
            {(!this.props.data.loading && this.props.data.viewer) ?
              <Footer {...this.props.data.viewer.entities} />
              : <Loader active />}
          </Menu.Item>
        </Sidebar>
        <Sidebar as={Menu} animation="overlay" width="wide" visible={this.state.notifications} vertical style={{ width: 350 }}>
          <UserNotifications onClose={this.closeDrawer} visible={this.state.notifications} />
        </Sidebar>
        <Sidebar.Pusher dimmed={this.state.drawer || this.state.whatsNew} onClick={this.closeDrawer} className={this.isMobile() ? 'md' : ''}>
          <ExpiredLimitBanner
            isBanner
            user={store.currentUser}
            organization={organization}
            onPress={(e) => {
              e.preventDefault();
              // eslint-disable-next-line no-unused-expressions
              this.openKnowMoreModal();
            }}
          />
          <DemonstrationBanner {...this.props} />
          {(isOverdue) &&
            <ElementModifier element={document.getElementById('root')} newClassName="withBanner" />
          }
          {organization && organization !== 'not found' && <TopCover organization={filter(TopCover.fragments.organization, organization)} isAdmin={isCurrentOrganizationAdmin || currentUser.isMaster} />}
          <Container style={{ marginTop: '40px' }} fluid>
            {this.props.children}
          </Container>
        </Sidebar.Pusher>
        {this.renderAppends()}
        <Snackbar />
        <NewSnackbar />
        {/* <Announcements /> */}
      </Sidebar.Pushable>
    );
  }
}